import Axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import {
    Accordion,
    Badge,
    Button,
    Card,
    Form,
    ListGroup,
    ProgressBar,
    Spinner,
} from "react-bootstrap";
import { API_URL } from "../config.js";
import { GalleryContext } from "./GalleryContext.js";
import AWS from "aws-sdk";

export const Gallery = (props) => {
    const { gallery, setGallery, getGalleryList } = useContext(GalleryContext);
    const [progress, setProgress] = useState(0);
    const [progressZip, setProgressZip] = useState(0);
    const [poll, setPoll] = useState();

    const handleSubmitInfo = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const data = Object.fromEntries(formData.entries());
        var res;

        if (data._id) {
            res = await Axios.post(API_URL + "/gallery/update", data);

            if (res.data?.error) {
                alert("Error");
            } else {
                alert("Saved!");
                setGallery(res.data);
            }
        } else {
            delete data._id;
            res = await Axios.post(API_URL + "/gallery/create", data);

            if (res.data?.error) {
                alert("Error");
            } else {
                setGallery(res.data);
                getGalleryList();
            }
        }
    };

    AWS.config.update({
        // aws_key
        accessKeyId: "AKIAU32NW4O7RT45PF5Y",
        secretAccessKey: "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",
    });

    const bucket = new AWS.S3({
        params: { Bucket: "static.reginafoster.us" },
        region: "us-east-2",
    });

    const uploadFile = async (file, filename, filetype) => {
        const params = {
            Key: gallery._id + "/" + filetype + "/" + filename,
            Body: file,
        };
        const res = await bucket
            .upload(params)
            .on("httpUploadProgress", (e) => {
                setProgressZip(Math.round((e.loaded / e.total) * 100));
            })
            .promise();
    };

    const getCount = async (filetype, total) => {
        const params = {
            Prefix: gallery._id + "/" + filetype + "/",
        };
        const res = await bucket.listObjectsV2(params).promise();
        setProgress(Math.round((res.Contents.length / total) * 100) ?? 1);
    };

    const dataURLToBlob = (dataURL) => {
        var BASE64_MARKER = ";base64,";
        if (dataURL.indexOf(BASE64_MARKER) == -1) {
            var parts = dataURL.split(",");
            var contentType = parts[0].split(":")[1];
            var raw = parts[1];

            return new Blob([raw], { type: contentType });
        }

        var parts = dataURL.split(BASE64_MARKER);
        var contentType = parts[0].split(":")[1];
        var raw = window.atob(parts[1]);
        var rawLength = raw.length;

        var uInt8Array = new Uint8Array(rawLength);

        for (var i = 0; i < rawLength; ++i) {
            uInt8Array[i] = raw.charCodeAt(i);
        }

        return new Blob([uInt8Array], { type: contentType });
    };

    useEffect(() => {
        if (progress == 100) {
            clearInterval(poll);
        }
    }, [progress]);

    const handleSubmission = async (e) => {
        if (
            window.confirm(
                "Click OK to upload " +
                    e.target.files.length +
                    " images for submission."
            )
        ) {
            setProgress(1);
            setPoll(
                setInterval(
                    () => getCount("submission", e.target.files.length),
                    1000
                )
            );

            for (let i = 0; i < e.target.files.length; i++) {
                const fileReader = new FileReader();
                const filename = e.target.files[i].name.replace("jpeg", "jpg");
                fileReader.onload = async (e) => {
                    var image = new Image();
                    image.onload = async () => {
                        var canvas = document.createElement("canvas"),
                            max_size = 2160,
                            width = image.width,
                            height = image.height;
                        if (width > height) {
                            if (width > max_size) {
                                height *= max_size / width;
                                width = max_size;
                            }
                        } else {
                            if (height > max_size) {
                                width *= max_size / height;
                                height = max_size;
                            }
                        }
                        canvas.width = width;
                        canvas.height = height;
                        canvas
                            .getContext("2d")
                            .drawImage(image, 0, 0, width, height);
                        var dataUrl = canvas.toDataURL("image/jpeg");
                        var resizedImage = dataURLToBlob(dataUrl);
                        const file = await new Response(
                            resizedImage
                        ).arrayBuffer();
                        await uploadFile(file, filename, "submission");

                        //thumb
                        max_size = 400;

                        if (width > height) {
                            if (width > max_size) {
                                height *= max_size / width;
                                width = max_size;
                            }
                        } else {
                            if (height > max_size) {
                                width *= max_size / height;
                                height = max_size;
                            }
                        }
                        canvas.width = width;
                        canvas.height = height;
                        canvas
                            .getContext("2d")
                            .drawImage(image, 0, 0, width, height);
                        var dataUrl = canvas.toDataURL("image/jpeg");
                        var resizedImage = dataURLToBlob(dataUrl);
                        const thumb = await new Response(
                            resizedImage
                        ).arrayBuffer();
                        await uploadFile(thumb, filename, "thumb");
                    };
                    image.src = e.target.result;
                };

                fileReader.readAsDataURL(e.target.files[i]);
            }

            if (gallery.is_submitted != 1) {
                const res = await Axios.post(API_URL + "/gallery/update", {
                    _id: gallery._id,
                    is_submitted: 1,
                });

                if (res.data?.error) {
                    alert("Error");
                } else {
                    setGallery(res.data);
                }
            }
        }
    };

    const handleRetouched = async (e) => {
        if (
            window.confirm(
                "Click OK to upload " +
                    e.target.files.length +
                    " retouched images."
            )
        ) {
            setProgress(1);
            setPoll(
                setInterval(
                    () => getCount("image", e.target.files.length),
                    1000
                )
            );

            for (let i = 0; i < e.target.files.length; i++) {
                const fileReader = new FileReader();
                const filename = e.target.files[i].name;
                fileReader.onload = async (e) => {
                    var image = new Image();
                    image.onload = async () => {
                        var canvas = document.createElement("canvas"),
                            max_size = 2160,
                            width = image.width,
                            height = image.height;
                        if (width > height) {
                            if (width > max_size) {
                                height *= max_size / width;
                                width = max_size;
                            }
                        } else {
                            if (height > max_size) {
                                width *= max_size / height;
                                height = max_size;
                            }
                        }
                        canvas.width = width;
                        canvas.height = height;
                        canvas
                            .getContext("2d")
                            .drawImage(image, 0, 0, width, height);
                        var dataUrl = canvas.toDataURL("image/jpeg");
                        var resizedImage = dataURLToBlob(dataUrl);
                        const file = await new Response(
                            resizedImage
                        ).arrayBuffer();
                        await uploadFile(
                            file,
                            filename.slice(0, -4) +
                                "_" +
                                width +
                                "_" +
                                height +
                                filename.slice(-4),
                            "image"
                        );
                    };
                    image.src = e.target.result;
                };

                fileReader.readAsDataURL(e.target.files[i]);
            }

            if (gallery.is_ready != 1) {
                const res = await Axios.post(API_URL + "/gallery/update", {
                    _id: gallery._id,
                    is_ready: 1,
                });

                if (res.data?.error) {
                    alert("Error");
                } else {
                    setGallery(res.data);
                }
            }
        }
    };

    const handleCover = async (e) => {
        if (e.target.files.length != 2) {
            alert("You have to select 2 files!");
            return;
        }

        setProgress(1);
        setPoll(
            setInterval(() => getCount("cover", e.target.files.length), 1000)
        );

        for (let i = 0; i < e.target.files.length; i++) {
            const fileReader = new FileReader();
            fileReader.onload = async (e) => {
                var image = new Image();
                image.onload = async () => {
                    var canvas = document.createElement("canvas"),
                        max_size = 2160,
                        width = image.width,
                        height = image.height;
                    if (width > height) {
                        if (width > max_size) {
                            height *= max_size / width;
                            width = max_size;
                        }
                    } else {
                        if (height > max_size) {
                            width *= max_size / height;
                            height = max_size;
                        }
                    }
                    canvas.width = width;
                    canvas.height = height;
                    canvas
                        .getContext("2d")
                        .drawImage(image, 0, 0, width, height);
                    var dataUrl = canvas.toDataURL("image/jpeg");
                    var resizedImage = dataURLToBlob(dataUrl);
                    const file = await new Response(resizedImage).arrayBuffer();
                    const filename =
                        width > height ? "cover_h.jpg" : "cover_v.jpg";

                    await uploadFile(file, filename, "cover");
                };
                image.src = e.target.result;
            };

            fileReader.readAsDataURL(e.target.files[i]);
        }

        if (gallery.is_cover != 1) {
            const res = await Axios.post(API_URL + "/gallery/update", {
                _id: gallery._id,
                is_cover: 1,
            });

            if (res.data?.error) {
                alert("Error");
            } else {
                setGallery(res.data);
            }
        }
    };

    const handleArchive = async (e) => {
        const fileReader = new FileReader();
        fileReader.onload = async (e) => {
            await uploadFile(e.target.result, "archive.zip", "archive");
        };

        fileReader.readAsArrayBuffer(e.target.files[0]);

        if (gallery.is_archive != 1) {
            const res = await Axios.post(API_URL + "/gallery/update", {
                _id: gallery._id,
                is_archive: 1,
            });

            if (res.data?.error) {
                alert("Error");
            } else {
                setGallery(res.data);
            }
        }
    };

    const handleSubmissionCancel = async (e) => {
        if (window.confirm("Delete submission?")) {
            const res = await Axios.post(API_URL + "/gallery/update", {
                _id: gallery._id,
                is_submitted: 0,
            });

            if (res.data?.error) {
                alert("Error");
            } else {
                setGallery(res.data);
            }
        }
    };

    const handleRetouchedCancel = async (e) => {
        if (window.confirm("Delete retouched photos?")) {
            const res = await Axios.post(API_URL + "/gallery/update", {
                _id: gallery._id,
                is_ready: 0,
            });

            if (res.data?.error) {
                alert("Error");
            } else {
                setGallery(res.data);
            }
        }
    };

    const handleCoverCancel = async (e) => {
        if (window.confirm("Delete covers?")) {
            const res = await Axios.post(API_URL + "/gallery/update", {
                _id: gallery._id,
                is_cover: 0,
            });

            if (res.data?.error) {
                alert("Error");
            } else {
                setGallery(res.data);
            }
        }
    };

    const handleArchiveCancel = async (e) => {
        if (window.confirm("Delete archive?")) {
            const res = await Axios.post(API_URL + "/gallery/update", {
                _id: gallery._id,
                is_archive: 0,
            });

            if (res.data?.error) {
                alert("Error");
            } else {
                setGallery(res.data);
            }
        }
    };

    const handleSubmittedCancel = async (e) => {
        if (window.confirm("Delete picked photos?")) {
            const res = await Axios.post(API_URL + "/gallery/update", {
                _id: gallery._id,
                submission: 0,
            });

            if (res.data?.error) {
                alert("Error");
            } else {
                setGallery(res.data);
            }
        }
    };

    useEffect(() => {
        if (gallery._id) {
            document.getElementById("gallery-form").reset();
        }
    }, [gallery]);

    if (gallery?.access_key) {
        return (
            <Accordion
                style={{ marginTop: "80px" }}
                activeKey={gallery._id ? undefined : "0"}
            >
                <Card>
                    <Card.Header>
                        <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey="0"
                        >
                            Step 1: Gallery information{" "}
                            {gallery._id && (
                                <Badge variant="success">DONE</Badge>
                            )}
                        </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body>
                            <Form onSubmit={handleSubmitInfo} id="gallery-form">
                                <Form.Control
                                    type="hidden"
                                    value={gallery._id ?? ""}
                                    name="_id"
                                />
                                <Form.Control
                                    type="hidden"
                                    value={gallery.share_key}
                                    name="share_key"
                                />

                                <Form.Group controlId="formName">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter gallery name"
                                        defaultValue={gallery.name ?? ""}
                                        name="name"
                                        required
                                    />
                                </Form.Group>

                                <Form.Group controlId="formDate">
                                    <Form.Label>Date</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter gallery date"
                                        defaultValue={gallery.date ?? ""}
                                        name="date"
                                        required
                                    />
                                </Form.Group>

                                <Form.Group controlId="formCount">
                                    <Form.Label>
                                        Number of retouched images
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="50"
                                        defaultValue={gallery.count ?? ""}
                                        name="count"
                                        required
                                    />
                                </Form.Group>

                                <Form.Group controlId="formKey">
                                    <Form.Label>Access key</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={gallery.access_key}
                                        readOnly
                                        name="access_key"
                                    />
                                </Form.Group>

                                <Button
                                    variant="primary"
                                    type="submit"
                                    size="sm"
                                >
                                    Save
                                </Button>
                            </Form>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Card.Header>
                        <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey="1"
                            disabled={
                                !gallery._id ||
                                (gallery.submission &&
                                    gallery.submission.length > 0)
                                    ? true
                                    : false
                            }
                        >
                            Step 2: Upload photos for submission{" "}
                            {gallery.is_submitted == 1 && (
                                <Badge variant="success">DONE</Badge>
                            )}
                        </Accordion.Toggle>
                        {gallery.is_submitted == 1 && (
                            <Badge
                                variant="danger"
                                style={{ cursor: "pointer" }}
                                onClick={handleSubmissionCancel}
                            >
                                CANCEL
                            </Badge>
                        )}
                    </Card.Header>
                    <Accordion.Collapse eventKey="1">
                        <Card.Body>
                            <Form id="gallery-form">
                                <Form.Group controlId="formSubmissionFile">
                                    <Form.File
                                        id="submission"
                                        label="Select files to upload"
                                        custom
                                        multiple
                                        onChange={handleSubmission}
                                        accept=".jpg,.JPG"
                                    />
                                </Form.Group>
                            </Form>
                            <div>
                                {progress > 0 ? (
                                    progress == 100 ? (
                                        <ProgressBar
                                            variant="success"
                                            now={progress}
                                            label="Done"
                                        />
                                    ) : (
                                        <ProgressBar
                                            animated
                                            now={progress}
                                            label={`${progress}%`}
                                        />
                                    )
                                ) : (
                                    ""
                                )}
                            </div>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Card.Header>
                        <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey="2"
                            disabled={
                                !gallery.submission ||
                                gallery.submission.length == 0 ||
                                !gallery._id ||
                                gallery.is_submitted != 1
                                    ? true
                                    : false
                            }
                        >
                            Step 3: Client picks photos{" "}
                            {gallery.submission
                                ? gallery.submission.length > 0 && (
                                      <Badge variant="success">DONE</Badge>
                                  )
                                : ""}
                        </Accordion.Toggle>{" "}
                        {gallery.submission && gallery.submission.length > 0 && (
                            <Badge
                                variant="danger"
                                style={{ cursor: "pointer" }}
                                onClick={handleSubmittedCancel}
                            >
                                CANCEL
                            </Badge>
                        )}
                    </Card.Header>
                    <Accordion.Collapse eventKey="2">
                        <Card.Body>
                            <ListGroup>
                                {gallery.submission &&
                                    gallery.submission?.map((file, index) => (
                                        <ListGroup.Item key={index}>
                                            {file}
                                        </ListGroup.Item>
                                    ))}
                            </ListGroup>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Card.Header>
                        <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey="3"
                            disabled={
                                !gallery.submission ||
                                gallery.is_submitted != 1 ||
                                !gallery._id ||
                                gallery.is_ready == 1
                                    ? true
                                    : false
                            }
                        >
                            Step 4: Upload retouched photos{" "}
                            {gallery.is_ready == 1 && (
                                <Badge variant="success">DONE</Badge>
                            )}
                        </Accordion.Toggle>
                        {gallery.is_ready == 1 && (
                            <Badge
                                variant="danger"
                                style={{ cursor: "pointer" }}
                                onClick={handleRetouchedCancel}
                            >
                                CANCEL
                            </Badge>
                        )}
                    </Card.Header>
                    <Accordion.Collapse eventKey="3">
                        <Card.Body>
                            <Form id="retouched-form">
                                <Form.Group controlId="formRetouchedFile">
                                    <Form.File
                                        id="retouched"
                                        label="Select files to upload"
                                        custom
                                        multiple
                                        onChange={handleRetouched}
                                        accept=".jpg,.JPG"
                                    />
                                </Form.Group>
                            </Form>
                            <div>
                                {progress > 0 ? (
                                    progress == 100 ? (
                                        <ProgressBar
                                            variant="success"
                                            now={progress}
                                            label="Done"
                                        />
                                    ) : (
                                        <ProgressBar
                                            animated
                                            now={progress}
                                            label={`${progress}%`}
                                        />
                                    )
                                ) : (
                                    ""
                                )}
                            </div>{" "}
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Card.Header>
                        <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey="4"
                            disabled={
                                !gallery.submission ||
                                gallery.is_submitted != 1 ||
                                !gallery._id ||
                                gallery.is_ready != 1 ||
                                gallery.is_cover == 1
                                    ? true
                                    : false
                            }
                        >
                            Step 5: Upload covers{" "}
                            {gallery.is_cover == 1 && (
                                <Badge variant="success">DONE</Badge>
                            )}
                        </Accordion.Toggle>{" "}
                        {gallery.is_cover == 1 && (
                            <Badge
                                variant="danger"
                                style={{ cursor: "pointer" }}
                                onClick={handleCoverCancel}
                            >
                                CANCEL
                            </Badge>
                        )}
                    </Card.Header>
                    <Accordion.Collapse eventKey="4">
                        <Card.Body>
                            <Form id="cover-form">
                                <Form.Group controlId="formCoverFile">
                                    <Form.File
                                        id="cover"
                                        label="Select files to upload"
                                        custom
                                        multiple
                                        onChange={handleCover}
                                        accept=".jpg,.JPG"
                                    />
                                </Form.Group>
                            </Form>
                            <div>
                                {progress > 0 ? (
                                    progress == 100 ? (
                                        <ProgressBar
                                            variant="success"
                                            now={progress}
                                            label="Done"
                                        />
                                    ) : (
                                        <ProgressBar
                                            animated
                                            now={progress}
                                            label={`${progress}%`}
                                        />
                                    )
                                ) : (
                                    ""
                                )}
                            </div>{" "}
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Card.Header>
                        <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey="5"
                            disabled={
                                !gallery.submission ||
                                gallery.is_submitted != 1 ||
                                !gallery._id ||
                                gallery.is_ready != 1 ||
                                gallery.is_cover != 1 ||
                                gallery.is_archive == 1
                                    ? true
                                    : false
                            }
                        >
                            Step 6: Upload ZIP archive{" "}
                            {gallery.is_archive == 1 && (
                                <Badge variant="success">DONE</Badge>
                            )}
                        </Accordion.Toggle>{" "}
                        {gallery.is_archive == 1 && (
                            <Badge
                                variant="danger"
                                style={{ cursor: "pointer" }}
                                onClick={handleArchiveCancel}
                            >
                                CANCEL
                            </Badge>
                        )}
                    </Card.Header>
                    <Accordion.Collapse eventKey="5">
                        <Card.Body>
                            <Form id="archive-form">
                                <Form.Group controlId="formArchiveFile">
                                    <Form.File
                                        id="archive"
                                        label="Select file to upload"
                                        custom
                                        onChange={handleArchive}
                                        accept=".zip"
                                    />
                                </Form.Group>
                            </Form>
                            <div>
                                {progressZip > 0 ? (
                                    progressZip == 100 ? (
                                        <ProgressBar
                                            variant="success"
                                            now={progressZip}
                                            label="Done"
                                        />
                                    ) : (
                                        <ProgressBar
                                            animated
                                            now={progressZip}
                                            label={`${progressZip}%`}
                                        />
                                    )
                                ) : (
                                    ""
                                )}
                            </div>{" "}
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        );
    }

    return <div style={{ marginTop: "40px" }}>Please select a gallery...</div>;
};
