import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Col, Row } from "react-bootstrap";
import { GalleryList } from "./components/GalleryList";
import GalleryContextProvider from "./components/GalleryContext";
import { Gallery } from "./components/Gallery";
import Protect from "react-app-protect";

function App() {
    return (
        <Protect
            boxTitle="Enter password"
            buttonLabel="Enter!"
            sha512="1c67a9aab963fac398b1f7c193b6227996ad813184dca9c17b1e51899b9ffe029e31258861528760484c89fa5250b991e1837a26110a95f729c78edbc9b09685"
        >
            <div>
                <div style={{ marginTop: 20, marginLeft: 50 }}>
                    <img src="/logo.png" width={150} />
                </div>
                <hr />
                <GalleryContextProvider>
                    <Row style={{ margin: "40px" }}>
                        <Col md="5">
                            <GalleryList />
                        </Col>
                        <Col md="7">
                            <Gallery />
                        </Col>
                    </Row>
                </GalleryContextProvider>
            </div>
        </Protect>
    );
}

export default App;
