import Axios from "axios";
import React, { createContext, useEffect, useState } from "react";
import { API_URL } from "../config";

export const GalleryContext = createContext();

const GalleryContextProvider = (props) => {
    const [galleryList, setGalleryList] = useState({});
    const [gallery, setGallery] = useState({});
    const getGalleryList = async () => {
        try {
            const res = await Axios.get(API_URL + "/gallery/view_info");
            setGalleryList(res.data);
        } catch (e) {
            setGalleryList([]);
        }
    };

    useEffect(() => {
        getGalleryList();
    }, [setGalleryList]);

    return (
        <GalleryContext.Provider
            value={{
                galleryList,
                setGallery,
                gallery,
                getGalleryList,
            }}
        >
            {props.children}
        </GalleryContext.Provider>
    );
};

export default GalleryContextProvider;
