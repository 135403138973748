import { Button, Col, Row } from "react-bootstrap";
import React, { useContext, useEffect, useState } from "react";
import { ListGroup } from "react-bootstrap";
import { GalleryContext } from "./GalleryContext.js";
import Axios from "axios";
import { API_URL } from "../config.js";
import ReactPaginate from "react-paginate";

export const GalleryList = (props) => {
    const { galleryList, setGallery, getGalleryList } =
        useContext(GalleryContext);
    const handleGalleryClick = (e) => {
        setGallery(galleryList.find((item) => item._id === e.target.id));
    };
    const handleNew = (e) => {
        setGallery({ access_key: generate(), share_key: generate() });
    };

    const handleDelete = async (e) => {
        console.log(e.target.id.substring(7));
        if (window.confirm("Delete gallery?")) {
            const res = await Axios.post(
                API_URL + "/gallery/delete/" + e.target.id.substring(6)
            );

            if (res.data?.error) {
                alert("Error");
            } else {
                setGallery({});
                getGalleryList();
            }
        }
    };

    const generate = () => {
        var result = "";
        var characters =
            "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        var charactersLength = characters.length;
        for (var i = 0; i < 8; i++) {
            result += characters.charAt(
                Math.floor(Math.random() * charactersLength)
            );
        }
        return result;
    };

    const [pageCount, setPageCount] = useState(0);
    const [offset, setOffset] = useState(0);
    const [selectedPage, setSelectedPage] = useState(null);

    useEffect(() => {
        if (galleryList.length > 0) {
            setPageCount(Math.ceil(galleryList.length / 10));
        } else {
            setPageCount(0);
        }
    }, [galleryList]);

    const handlePageClick = (data) => {
        setSelectedPage(data.selected);
        setOffset(Math.ceil(data.selected * 10));
    };

    if (galleryList.length > 0) {
        return (
            <>
                <Button
                    variant="primary"
                    size="sm"
                    style={{ marginBottom: "10px" }}
                    onClick={handleNew}
                >
                    + New gallery
                </Button>
                <Row>
                    <Col md="12" className="h5">
                        List of galleries:
                    </Col>
                </Row>
                <ListGroup>
                    {galleryList
                        .slice(offset, offset + 10)
                        .map((item, index) => (
                            <ListGroup.Item key={index} action>
                                <Row>
                                    <Col
                                        md={10}
                                        id={item._id}
                                        onClick={handleGalleryClick}
                                    >
                                        {item.name}
                                    </Col>
                                    <Col md={2}>
                                        <Button
                                            size="sm"
                                            variant="danger"
                                            id={"delete" + item._id}
                                            onClick={handleDelete}
                                        >
                                            Delete
                                        </Button>
                                    </Col>
                                </Row>
                            </ListGroup.Item>
                        ))}
                </ListGroup>
                <ReactPaginate
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                    forcePage={selectedPage}
                />
            </>
        );
    }

    return "Loading...";
};
